import uniqid from "uniqid";
import { education } from "../../portfolio";
import EducationContainer from "../EducationContainer/EducationContainer";
import "./Education.css";

const Education = () => {
  if (!education.length) return null;

  return (
    <section id="education" className="section educations">
      <h2 className="section__title">Education 🧑‍🎓</h2>

      <div className="education__grid">
        {education.map((role) => (
          <EducationContainer key={uniqid()} education={role} />
        ))}
      </div>
    </section>
  );
};

export default Education;
