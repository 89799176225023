import "./EducationContainer.css";

const EducationContainer = ({ education }) => (
  <div className="education">
    <h5 className="education__label">{education.label}</h5>
    <h3>{education.name}</h3>
    <h4 className="education__courseTitle">{education.courseTitle}</h4>

    <p className="education__description">{education.description}</p>
  </div>
);

export default EducationContainer;
