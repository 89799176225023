const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: "https://danbeech.co.uk",
  title: "db.",
};

const about = {
  // all the properties are optional - can be left empty or deleted
  name: "Dan Beech 👋",
  role: "Mobile & Web Front-End Engineer",
  descriptionOne:
    "I live in Liverpool, UK with my two cats and chihuahua 🐱🐱🐶",
  descriptionTwo:
    "You can usually catch me playing music or catching up with friends over a coffee 🥁☕️",
  social: {
    linkedin: "https://www.linkedin.com/in/dan-beech/",
    github: "https://github.com/danbeech",
  },
};

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: "Huffy",
    description: "A dating app I designed & built in my spare time over a couple of months.",
    stack: ["Flutter", "Dart", "Postgres", "WebFlow", "Figma"],
    livePreview: "https://huffy.app",
  },
  {
    name: "Awear",
    description:
      "An Apple Watch app to aid map awareness in League of Legends. Also utilises the LoL API & Apple HealthKit to measure the players highest recorded heart rate during a game.",
    stack: ["Swift", "WatchKit"],
    livePreview: "https://apps.apple.com/gb/app/awear/id1553099342",
  },
  {
    name: "SoSus",
    description:
      "A website made using Vue that I used to test running a Python child process from Express. It searches a list of sites for usernames and provides back a list of links that you can view.",
    stack: ["Vue", "JavaScript", "Express", "Python"],
    livePreview: "https://sosus.xyz",
  },
  {
    name: "Gesture Control in 3 Dimensional Space",
    description:
      "This was my university dissertation, it allows you to fly a drone with wrist movements. I managed to pack in sensor data readings and processing, machine learning, connection to an iPhone, and connection to a drone into this project.",
    stack: ["Swift", "WatchKit"],
    sourceCode: "https://github.com/danbeech/AppleWatchDroneControl",
  },
];

const experience = [
  // experience can be added an removed
  // if there are no roles, Experience section won't show up
  {
    name: "Bloom Money",
    label: "Current:",
    description: "I'm the sole Flutter Engineer. I work on all aspects of the app and manage the introduction of new features in the app.",
  },
  {
    name: "PixelBeard",
    label: "Previous:",
    description:
      "I worked with Flutter & Dart. I also worked on web projects and contributed to projects using Vue, Angular, Tailwind, and Material Design.",
  },
  {
    name: "DefProc Engineering",
    label: "Previous:",
    description:
      "I was the sole developer and created desktop and full-stack web apps using Vue, Vuetify, Express, Electron. These apps interfaced with micro controllers through serial connection and MQTT using Python.",
  },
];

const education = [
  // education can be added an removed
  // if there are no roles, Education section won't show up
  {
    label: "Previous:",
    name: "University of Liverpool",
    courseTitle: "Software Development",
  },
  {
    label: "Previous:",
    name: "Google Professional Certificate",
    courseTitle: "UX Design",
  },
];

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  "Flutter",
  "Vue",
  "Angular",
  "iOS",
  "WatchKit",
  "HTML",
  "CSS",
  "Tailwind",
  "JavaScript",
  "Express",
  "Dart",
  "Swift",
  "Python",
  "OpenCV",
  "Micro Controllers",
  "Photoshop",
  "Illustrator",
  "Sketch",
  "Figma",
  "React",
  "SASS",
  "Material UI",
  "Git",
];

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: "monolp.dan@gmail.com",
};

export { header, about, projects, experience, education, skills, contact };
