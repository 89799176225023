import "./Footer.css";

const Footer = () => (
  <footer className="footer">
    <a href="#top" className="link footer__link">
      Thanks for stopping by! Click me to go back to the top 👀
    </a>
  </footer>
);

export default Footer;
