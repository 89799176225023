import "./ExperienceContainer.css";

const ExperienceContainer = ({ experience }) => (
  <div className="experience">
    <h5 className="experience__label">{experience.label}</h5>
    <h3>{experience.name}</h3>

    <p className="experience__description">{experience.description}</p>
  </div>
);

export default ExperienceContainer;
